<template>
    <div class="header">
        <span class="label">
        {{ title }}
        </span>
        <router-link :to="{path:url}" class="card">

        <div class="more">
        查看更多
        <span class="icon">
            <i class="el-icon-arrow-right"></i> 
        </span>
        </div>
        </router-link>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        // 值
        title: [String],
        url: [String],
    },
    data () {
      return {};
    },
    mounted () { },
    methods: {},
    components: {},
  };
  </script>
  
  <style lang='scss' scoped>
  .header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 1px;
      height: 78px;
      .label{
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 0;
      }
      .more{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        .icon{
          display: inline-block;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: #9c9c9c;
          text-align: center;
          margin-left: 8px;
          .el-icon-arrow-right{
            font-size: 10px;
            color: #fff;
            line-height: 14px;
          }
        }
        
      }
    }
  </style>
  