<template>
  <div class="home">
    <section class="extend-wrap">
      <div class="container">
        <img src="@/assets/image/banner.png" class="banner" alt="">
        <div class="study">
          <div class="user">
            <img :src="userInfo.avatar" class="avatar" alt="">
            <div class="info">
              <div class="name">{{ userInfo.userName }}</div>
              <div class="platform">{{ userInfo.platform }}</div>
            </div>
          </div>
          <div class="Condition">
            <div class="item">
              <p class="number">{{ userInfo.duration }}</p>
              <p class="label">学习时长</p>
            </div>
            <div class="item">
              <p class="number">{{ userInfo.task }}</p>
              <p class="label">我的任务</p>
            </div>
          </div>
          <ul class="card-box">
            <li class="card">
              <img src="@/assets/image/kecheng.png" alt="">
              <p>课程</p>
            </li>
            <li class="card">
              <img src="@/assets/image/xiangmu.png" alt="">
              <p>项目</p>
            </li>
            <li class="card">
              <img src="@/assets/image/kaoshi.png" alt="">
              <p>考试</p>
            </li>
          </ul>
          <el-button type="primary" round class="button">查看更多</el-button>
        </div>
      </div>
    </section>
    <section class="container">
      <div class="module">
        <ModuleHeader title="推荐课程" url="/course/lists"/>
        <ul class="courseList">
          <li class="course-item" v-for="(item,index) in courseList" :key="index">
            <router-link :to="{path:'/course/detail?id='+item.id}" class="card">

              <div class="thumbnail">
                <img :src="item.upload_file ?item.upload_file:thumbnail" alt="">
                <span>{{ item.hour }}</span>
              </div>
              <div class="info-box">
                <div class="title">{{ item.title }}</div>
                <div class="info">
                  <div class="info-left">
                    <img class="avatar" :src="item.avatar?item.avatar:defaultAvatar" alt="">
                    <span>{{ item.lecturer || '暂无讲师' }}</span>
                  </div>
                  <div class="info-right">
                    <span>{{ item.heat }}</span>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <!-- <div class="module">
        <ModuleHeader title="课程专题" url=""/>
        <ul class="courseList">
          <li class="course-item" v-for="(item,index) in CourseTopics" :key="index">
            <a href="" class="card">
              <div class="thumbnail">
                <img :src="item.thumbnail" alt="">
                <span>{{ item.time }}</span>
              </div>
              <div class="info-box">
                <div class="title">{{ item.title }}</div>
              </div>
            </a>
          </li>
        </ul>
      </div> -->
      <div class="module">
        <ModuleHeader title="金牌讲师" url="teacher/list"/>
        <ul class="lecturerList">
          <li class="lecturer-item" v-for="(item,index) in GoldLecturer" :key="index">
            <router-link :to="{path:'/teacher/detail?id='+item.id}" class="card">

              <div class="thumbnail">
                <img :src="item.avatar?item.avatar:lecturer" alt="">
              </div>
              <div class="info-box">
                <div class="name">{{ item.name }}</div>
                <span class="level">{{ item.level_name }}</span>
              </div>
              <div class="mask">
                <div class="lecturerName">{{ item.name }}</div>
                <div class="introduce">介绍: {{ item.introduction || '暂无' }}</div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="module" v-if="newsTotal > 0">
        <ModuleHeader title="新闻动态" url="/news/lists"/>
        <ul class="two-columns">
          <li class="item" v-for="(item,index) in newsList" :key="index">
            <router-link :to="{path:'/news/detail?id='+item.news_id}" class="card">
              <div class="thumbnail">
                <img :src="item.image" class="newImage" alt="">
              </div>
              <div class="content">
                <div class="title">{{ item.title }}</div>
                <div class="describe">{{ item.brief }}</div>
                <div class="bottom">
                  <span class="time">{{ item.created_at }}</span>
                  <i class="el-icon-view"></i>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <!-- <div class="module">
        <ModuleHeader title="热门知识" url=""/>
        <ul class="two-columns">
          <li class="item2" v-for="(item,index) in knowledgeList" :key="index">
            <a href="" class="card2">
              <div class="title">{{ item.title }}</div>
              <div class="card2bottom">
                <ul class="card2content">
                  <li class="card2item">
                    <i class="el-icon-chat-line-round"></i>
                    <span class="number">{{ item.comment }}</span>
                  </li>
                  <li class="card2item">
                    <i class="el-icon-download"></i>
                    <span class="number">{{ item.comment }}</span>
                  </li>
                  <li class="card2item">
                    <i class="el-icon-chat-line-round"></i>
                    <span class="number">{{ item.comment }}</span>
                  </li>
                  <li class="card2item">
                    <i class="el-icon-view"></i>
                    <span class="number">{{ item.comment }}</span>
                  </li>
                </ul>
                <span class="time">{{ item.time }}</span>
              </div>
            </a>
          </li>
        </ul>
      </div> -->
    </section>
  </div>
</template>

<script>
import {getSyllabusList, getNews} from "@/api/syllabus/index";
import defaultAvatar from '@/assets/image/default.png'
import course1 from '@/assets/image/course1.png'
import course2 from '@/assets/image/course2.png'
import lecturer from '@/assets/image/lecturer.png'
import avatar from '@/assets/image/avatar.png'
import news from '@/assets/image/news.png'
import news1 from '@/assets/image/news2.png'
import ModuleHeader from '@/components/homeModelHeader/index.vue'

export default {
  name: "Login",
  components: {ModuleHeader},
  data() {
    return {
      lecturer: lecturer,
      userInfo: {
        avatar: avatar,
        userName: "逍遥",
        platform: "学创联盟",
        duration: '0.61h',
        task: 2,
      },
      noticeList: [],
      defaultAvatar: defaultAvatar,
      courseList: [
        // {
        //   title:'与压力共舞：新员工压力管理',
        //   thumbnail:course1,
        //   hour:'19:43',
        //   avatar:'',
        //   heat:'1',
        //   lecturer:''
        // },
        // {
        //   title:'压力杀毒：员工健康心理建设',
        //   thumbnail:course2,
        //   time:'19:43',
        //   avatar:'',
        //   heat:'1',
        //   lecturer:''
        // },
        // {
        //   title:'基层主管的自我压力管理',
        //   thumbnail:course1,
        //   time:'19:16',
        //   avatar:'',
        //   heat:'1',
        //   lecturer:''
        // },
        // {
        //   title:'企业变革下的压力管理',
        //   thumbnail:course2,
        //   time:'18:44',
        //   avatar:'',
        //   heat:'1',
        //   lecturer:''
        // },
      ],
      CourseTopics: [
        {
          title: '销售管理专题',
          thumbnail: course1,
        },
        {
          title: '文档操作专题',
          thumbnail: course2,
        },
        {
          title: '工作管理专题',
          thumbnail: course1,
        },
        7
      ],
      GoldLecturer: [
        {
          avatarImg: lecturer,
          lecturerName: '老虎',
          type: '金牌讲师',
          introduce: '士大夫十分十分大师傅'
        },
      ],
      newsTotal: 0,
      newsList: [],
      knowledgeList: [
        {
          title: 'Traingo学习管理系统移动学员端操作手册20230301',
          comment: 1,
          likes: 2,
          looks: 2,
          download: 3,
          time: '2023-06-05'
        },
        {
          title: 'Traingo学习管理系统PC学员端操作手册20230301',
          comment: 1,
          likes: 4,
          looks: 2,
          download: 3,
          time: '2023-06-05'
        },
        {
          title: 'Traingo学习管理系统后台管理端操作手册20230301',
          comment: 11,
          likes: 2,
          looks: 2,
          download: 3,
          time: '2023-06-05'
        },
      ]
    };
  },
  mounted() {
    this.getSyllabusList();
    this.getNews();
    this.getNotice();
  },
  methods: {

    initInfo() {
      var that = this;
      // companyMydata({}).then((response) => {
      //   console.log(response);
      //   if(response.code==200){
      //     that.companyInfo=response.data
      //   }else{

      //     that.$message.error(response.msg?response.msg:"请求错误");
      //   }
      // });
    },
    getNews() {
      var that = this;
      getNews({list_rows: 4}).then((response) => {
        if (response.code == 200) {
          that.newsList = response.data.news.data;
          that.newsTotal = response.data.news.total;
          that.GoldLecturer = response.data.teachers;
        } else {

          that.$message.error(response.msg ? response.msg : "请求错误");
        }
      });
    },

    getNotice() {
      var that = this;
      // noticePlay({}).then((response) => {
      //   console.log(response);
      //   if(response.code==200){
      //     that.noticePageProps.records = response.data.data;
      //      that.noticePageProps.total = response.data.total;
      //   }else{

      //     that.$message.error(response.msg?response.msg:"请求错误");
      //   }
      // });
    },

    // 当前页变更
    handleCurrentChange(num) {
      this.noticePageProps.pageNum = num
      this.getNotice()
    },

    getSyllabusList() {

      getSyllabusList({list_rows: 4}).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.courseList = res.data.course.data
        }
      })
    },

    // 每页显示页面数变更
    handleSizeChange(size) {
      this.noticePageProps.pageSize = size
      this.getNotice()
    },

    goNotice() {
      this.$router.push({name: "application-notice-list"});
    },
    goReport() {
      this.$router.push({name: "application-report-list"});
    },
    goApproval() {
      this.$router.push({name: "application-approval-list"});
    },
    goClock() {
      this.$router.push({name: "application-clock-report-day"});
    }
  },
};
</script>

<style lang='scss' scoped>
.home {
  .extend-wrap {
    background: rgb(89, 99, 253);
    height: 322px;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .banner {
        width: 720px;
        height: 320px;
        margin-left: 210px;
      }

      .study {
        width: 250px;
        height: 300px;
        background: #fff;
        border-radius: 10px;
        padding: 20px 20px;
        box-sizing: border-box;

        .user {
          display: flex;

          .avatar {
            width: 50px;
            height: 50px;
            margin-right: 10px;
          }

          .info {
            width: calc(100% - 60px);

            .name {
              line-height: 30px;
              min-height: 30px;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
              overflow: hidden;
              font-size: 16px;
            }

            .platform {
              color: #a4a4a4;
              line-height: 22px;
              min-height: 22px;
              font-size: 14px;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }

        .Condition {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          align-items: unset;
          margin: 15px 0;

          .item {
            text-align: center;
            flex: 1;
            font-size: 12px;
            margin-bottom: 0;
            color: #999;
            line-height: 24px;

            .number {
              font-size: 16px;
              font-weight: 700;
              color: #333;

            }
          }
        }

        .card-box {
          display: flex;
          justify-content: space-between;

          .card {
            margin: 0 auto;
            cursor: pointer;

            img {
              width: 30px;
              height: 30px;
            }

            p {
              line-height: 32px;
              font-size: 12px;
              color: #999;
              text-align: center;
            }

            &:hover {
              p {
                color: #1890ff;
              }
            }
          }
        }

        .button {
          width: 204px;
          height: 44px;
          font-weight: 500;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          background: #266fff;
          border-radius: 22px;
          margin: 16px auto 0;
          cursor: pointer;
        }
      }
    }


  }

  .module {
    margin-bottom: 24px;

    .courseList {
      display: flex;
      // justify-content: space-between;
      background-color: #eff1f4;

      .course-item {
        width: 282px;
        margin-right: 24px;

        &:nth-child(4) {
          margin-right: 0;
        }

        .card {
          position: relative;
          background-color: #fff;
          transition: all .3s ease;
          display: inline-block;

          &:hover {
            transform: translateY(-8px);
            box-shadow: 0 6px 12px 2px #f0f0f0;
          }

          .info-box {
            padding: 20px;

            .title {
              line-height: 24px;
              min-height: 24px;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
              overflow: hidden;
              font-size: 18px;
              font-weight: 500;
              color: #333;

            }

            .info {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 20px;

              .info-left {
                display: flex;
                align-items: center;

                .avatar {
                  width: 30px;
                  height: 30px;
                  margin-right: 10px;
                }
              }
            }
          }

          &:hover .title {
            color: #1890ff;
          }

          .thumbnail {
            width: 282px;
            height: 158px;
            position: relative;

            img {
              width: 100%;
              height: 100%;
            }

            span {
              position: absolute;
              right: 10px;
              bottom: 10px;
              font-size: 12px;
              color: #fff;
            }
          }
        }

      }
    }

    .lecturerList {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;

      .lecturer-item {
        width: 180px;
        height: 266px;
        background: #fff;
        position: relative;
        margin-right: 24px;

        &:nth-child(6) {
          margin-right: 0;
        }

        .thumbnail {
          width: 180px;
          height: 210px;

          img {
            width: 180px;
            height: 210px;
          }

        }

        .info-box {
          padding: 10px 10px 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;

          .level {
            margin-left: 20px;
            padding: 2px 5px;
            background: rgba(55, 125, 255, .1);
            border-radius: 4px;
            color: #266fff;
          }

          .name {
            flex: 1;
            line-height: 22px;
            min-height: 22px;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            overflow: hidden;
            color: #333;
          }
        }

        .mask {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 20px 10px;
          transition: all .3s ease;
          background-color: rgba(0, 0, 0, .6);
          font-size: 14px;
          color: #fff;
          opacity: 0;


          .introduce {
            margin-top: 16px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 6; /* number of lines to show */
            -webkit-box-orient: vertical;
          }
        }

        &:hover .mask {
          opacity: 0.7;
        }
      }
    }

    .two-columns {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        width: 588px;
        height: 130px;
        margin-bottom: 24px;

        .card {
          position: relative;
          background-color: #fff;
          transition: all .3s ease;
          display: inline-block;
          display: flex;
          padding: 20px;
          box-sizing: border-box;

          &:hover {
            transform: translateY(-8px);
            box-shadow: 0 6px 12px 2px #f0f0f0;
          }

          &:hover .content .title {
            color: #266fff;
          }

          .newImage {
            width: 160px;
            height: 90px;
          }

          .content {
            width: calc(100% - 180px);
            height: 100%;
            padding-left: 20px;

            .title {
              font-size: 18px;
              line-height: 30px;
              min-height: 30px;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
              overflow: hidden;
              color: rgba(0, 0, 0, .85);
              font-weight: 500;
            }

            .describe {
              line-height: 20px;
              min-height: 20px;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
              overflow: hidden;
              color: #999;
            }

            .bottom {
              display: flex;
              justify-content: space-between;
              color: #999;
              font-size: 12px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: row;
              margin-top: 25px;

              .time {

              }

              .el-icon-view {
                font-size: 16px;
              }
            }
          }

        }
      }

      .item2 {
        width: 588px;
        height: 117px;
        margin-bottom: 24px;

        .card2 {
          position: relative;
          background-color: #fff;
          transition: all .3s ease;
          display: inline-block;
          width: 100%;
          height: 100%;
          padding: 20px;
          box-sizing: border-box;

          &:hover {
            transform: translateY(-8px);
            box-shadow: 0 6px 12px 2px #f0f0f0;
          }

          &:hover .title {
            color: #266fff;
          }

          .title {
            line-height: 24px;
            min-height: 24px;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            overflow: hidden;
            font-size: 16px;
            font-weight: 500;
            color: #333;
          }

          .card2bottom {
            display: flex;
            justify-content: space-between;
            padding: 20px 0 10px;
            border-bottom: 1px solid #eee;
            color: #999;
            font-size: 16px;
          }

          .card2content {
            display: flex;
            width: 60%;
            justify-content: space-between;

            .number {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

}
</style>
